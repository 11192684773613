import { render, staticRenderFns } from "./CustomerDetailsInfo.vue?vue&type=template&id=18c1189b&scoped=true"
import script from "./CustomerDetailsInfo.vue?vue&type=script&lang=js"
export * from "./CustomerDetailsInfo.vue?vue&type=script&lang=js"
import style0 from "./CustomerDetailsInfo.vue?vue&type=style&index=0&id=18c1189b&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c1189b",
  null
  
)

export default component.exports